import React, { useState } from "react";
import { Form, Col, InputGroup, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styles from "./LoginForm.module.css";

const LoginForm = ({setIsLoggedIn , setIsLoggedOut}) => {
  const [validated, setValidated] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch("https://be-csc-map.mohamedradwan.me/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: username,
          password: password,
        }),
      });

      console.log("Response status:", response.status);

      if (response.ok) {
        setIsLoggedIn(true);
        setIsLoggedOut(false);
        console.log("Login successful");
        navigate("/countries-table", { replace: true });
      } else {
        console.log("Login failed");
        alert("Invalid username or password");
      }
    } catch (error) {
      console.error("Error during login:", error);
    } finally {
      console.log("Finally block");
      setValidated(true);
    }
  };

  return (
    <Form
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      className={styles.container}
    >
      <div className={styles.innerContainer}>
        <p>Welcome</p>
        <Form.Group
          as={Col}
          controlId="validationCustomUsername"
          className={styles.inputElement}
        >
          <Form.Label>Username</Form.Label>
          <InputGroup hasValidation>
            <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
            <Form.Control
              type="text"
              placeholder="Username"
              aria-describedby="inputGroupPrepend"
              required
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              Please choose a username.
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        <Form.Group
          as={Col}
          controlId="validationCustomPassword"
          className={styles.inputElement}
        >
          <Form.Label>Password</Form.Label>
          <InputGroup hasValidation>
            <Form.Control
              type="password"
              placeholder="Password"
              aria-describedby="inputGroupPrepend"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid password.
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        <Button type="submit">Login</Button>
      </div>
    </Form>
  );
};

export default LoginForm;
