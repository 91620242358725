// CountriesTable.jsx
import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import "bootstrap/dist/css/bootstrap.min.css";
import styles from "./CountriesTable.module.css";
import { Button, Form } from "react-bootstrap";
import CountryStats from "../modal/CountryStats";
import { FaFilter } from "react-icons/fa";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { useNavigate } from 'react-router-dom';


function CountriesTable({ setIsLoggedOut }) {
  const [tableData, setTableData] = useState([]);
  const [columnNames, setColumnNames] = useState([]);
  const [editableCell, setEditableCell] = useState(null);
  const [showStatsModal, setShowStatsModal] = useState(false);
  const [selectedItemName, setSelectedItemName] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [countryStatsData, setCountryStatsData] = useState([]);
  const [countryFilter, setCountryFilter] = useState("");
  const navigate = useNavigate();


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://be-csc-map.mohamedradwan.me/api/"
        );
        const data = await response.json();
        setTableData(data.Items);

        const allKeys = data.Items.reduce((keys, item) => {
          Object.keys(item).forEach((key) => {
            if (!keys.includes(key)) {
              keys.push(key);
            }
          });
          return keys;
        }, []);
        setColumnNames(allKeys);
      } catch (error) {
        console.error("Error fetching data from DynamoDB:", error);
      }
    };

    fetchData();
  }, [showStatsModal]);

  console.log(tableData);
  const handleLogout = () => {
    // Add any additional logout logic if needed
    setIsLoggedOut(true);
  };

  const handleEdit = (rowIndex, columnName) => {
    setEditableCell({ rowIndex, columnName });
  };

  const navigateToEditDataPage = (item) => {
    navigate('/edit-data', { state: { countryData: item } });
  };

  const handleUpdate = async (value, itemId, columnName) => {
    const rowData = tableData.find((item) => item.id.S === itemId);
  
    // Reformat "Country Stats" to match the desired format
    const countryStatsFormatted = rowData['Country Stats']?.L?.map(stat => {
      const formattedStat = {};
      Object.entries(stat.M).forEach(([key, value]) => {
        // Assuming all values in "Country Stats" are of type String
        formattedStat[key] = value.S;
      });
      return formattedStat;
    }) || []; // Default to an empty array if "Country Stats" is not present
  
    const updatedRow = {
      id: itemId,
      ...Object.keys(rowData).reduce((acc, key) => {
        if (key !== 'Country Stats') { // Exclude "Country Stats" from this mapping
          acc[key] = rowData[key].S; // Extract the value without "S"
        }
        return acc;
      }, {}),
      [columnName]: value, // Use the value directly without the "S" attribute
      'Country Stats': countryStatsFormatted, // Add the formatted "Country Stats"
    };
  
    const updatedTableData = tableData.map((item) => {
      if (item.id.S === itemId) {
        return {
          ...item,
          [columnName]: { S: value },
          // You may also need to handle the update logic for "Country Stats" if it's editable
        };
      }
      return item;
    });
  
    setTableData(updatedTableData);
  
    // Trigger the PUT request
    try {
      const response = await fetch(
        `https://be-csc-map.mohamedradwan.me/api/update-country/${itemId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedRow),
        }
      );
  
      if (!response.ok) {
        console.error(
          `Failed to update item with ID ${itemId}. Server responded with:`,
          response.status
        );
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  
    setEditableCell(null);
  };
  

  const handleViewStatsClick = (itemName, itemId) => {
    const rawCountryStatsData =
      tableData.find((item) => item.id.S === itemId.S)?.["Country Stats"]?.L ||
      [];

    console.log("Raw Country Stats Data:", rawCountryStatsData);

    const formattedCountryStats = rawCountryStatsData.map((item) => {
      const formattedItem = {};
      Object.entries(item.M).forEach(([key, value]) => {
        formattedItem[key] = value.S;
      });
      return formattedItem;
    });

    console.log("Formatted Country Stats Data:", formattedCountryStats);

    setShowStatsModal(true);
    setSelectedItemName(itemName.S);
    setSelectedItemId(itemId.S);
    setCountryStatsData(formattedCountryStats);
  };

  const handleStatsModalClose = () => {
    setShowStatsModal(false);
    setSelectedItemName(null);
    setSelectedItemId(null);
  };

  const renderStatsColumn = (itemName, itemId) => {
    return (
      <td key={itemId}>
        <Button onClick={() => handleViewStatsClick(itemName, itemId)}>
          View Stats
        </Button>
      </td>
    );
  };

  const renderTableHeader = () => {
    if (columnNames.length === 0) return null;

    const updatedColumnNames = [
      "#",
      {
        name: "Country",
        filterable: true,
      },
      "Country Stats",

      ...columnNames
        .filter((col) => col !== "Country Stats")
        .filter((col) => col !== "Country"),
    ];

    return (
      <tr className={styles.tableHeading}>
        {updatedColumnNames.map((column, index) => (
          <th key={index} className={index < 2 ? styles.stickyHeader : ""}>
            {column.name === "Country" && column.filterable ? (
              <div className={styles.filterContainer}>
                <span>{column.name}</span>
                <DropdownButton
                  id="dropdown-basic-button"
                  title={<FaFilter />}
                  className="dropBtn"
                >
                  <Dropdown.Item>
                    <Form.Control
                      placeholder="Filter..."
                      onChange={(e) => handleCountryFilter(e.target.value)}
                      onClick={(e) => e.stopPropagation()} // Prevent closing on input click
                    />
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            ) : (
              <span>{column}</span>
            )}
          </th>
        ))}
      </tr>
    );
  };
  // console.log(tableData);
  const handleCountryFilter = (value) => {
    setCountryFilter(value);
  };

  const renderTableRows = () => {
    return tableData.map((item, rowIndex) => {
      const isFiltered = item.Country?.S.toLowerCase().includes(
        countryFilter.toLowerCase()
      );

      if (!isFiltered) {
        return null; // Skip rendering if the item is not included in the filter
      }

      return (
        <tr key={rowIndex} className={styles.cell}>
          <td className={`${styles.stickyCell} ${styles.stickyLeft}`}>
            {rowIndex + 1}
          </td>
          <td
            className={`${styles.stickyCell} ${styles.stickyLeft}`}
            onClick={() => navigateToEditDataPage(item)}
          >
            <span
              style={{
                cursor: "pointer",
                textDecoration:"underline",
                fontWeight:"bold",
                color:"blue",
                
              }}
            >
              {item.Country?.S || ""}
            </span>
          </td>
          {renderStatsColumn(item.Country, item.id)} {/* Move this line here */}
          {columnNames.map(
            (columnName, columnIndex) =>
              columnName !== "Country" &&
              columnName !== "Country Stats" &&
              columnName !== "#" && (
                <td
                  key={columnIndex}
                  onClick={() => handleEdit(rowIndex, columnName)}
                >
                  {editableCell &&
                  editableCell.rowIndex === rowIndex &&
                  editableCell.columnName === columnName ? (
                    <Form.Control
                      type="text"
                      value={item[columnName]?.S || ""}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setTableData((prevData) =>
                          prevData.map((prevItem, i) =>
                            i === rowIndex
                              ? {
                                  ...prevItem,
                                  [columnName]: { S: newValue },
                                }
                              : prevItem
                          )
                        );
                      }}
                      onBlur={() =>
                        handleUpdate(
                          item[columnName]?.S || "",
                          item.id.S,
                          columnName
                        )
                      }
                    />
                  ) : (
                    <span>{item[columnName]?.S || ""}</span>
                  )}
                </td>
              )
          )}
        </tr>
      );
    });
  };

  return (
    <div className={styles.container}>
      <Table responsive hover bordered size="lg" className="h-25">
        <thead>{renderTableHeader()}</thead>
        <tbody>{renderTableRows()}</tbody>
      </Table>

      <div style={{ marginTop: "30px" }}>
        {/* Your existing CountriesTable component content */}
        <Button onClick={handleLogout}>Logout</Button>
      </div>

      {/* The modal for viewing stats */}
      {showStatsModal && (
        <CountryStats
          countryName={selectedItemName}
          countryId={selectedItemId}
          countryStatsData={countryStatsData}
          onClose={handleStatsModalClose}
        />
      )}
    </div>
  );
}

export default CountriesTable;
