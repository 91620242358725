import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import "./App.css";
import CountriesTable from "./components/table/CountriesTable";
import LoginForm from "./components/loginForm/LoginForm";
import { useState } from "react";
import EditData from "./components/table/EditData";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoggedOut, setIsLoggedOut] = useState(true);

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route
            path="/countries-table"
            element={
              isLoggedIn && !isLoggedOut ? (
                <CountriesTable setIsLoggedOut={setIsLoggedOut} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/"
            element={
              <LoginForm
                setIsLoggedIn={setIsLoggedIn}
                setIsLoggedOut={setIsLoggedOut}
              />
            }
          />
          <Route path="/edit-data" element={<EditData />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
