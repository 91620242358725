import React, { useState } from "react";
import { Dropdown, Button } from "react-bootstrap";
import Modal from "./Modal";
import styles from "./CountryStats.module.css";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";

const CountryStats = ({
  countryName,
  countryId,
  countryStatsData,
  onClose,
}) => {
  const [formData, setFormData] = useState({
    iconPath: "",
    text: "",
  });
  console.log(countryStatsData);
  const [selectedIcon, setSelectedIcon] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleIconChange = (selectedIcon) => {
    setSelectedIcon(selectedIcon);

    // Check if the selected icon path already exists
    const existingStat = countryStatsData.find(
      (stat) => stat.iconPath === selectedIcon
    );

    // If the icon path exists, set the text
    if (existingStat) {
      setFormData((prevData) => ({
        ...prevData,
        iconPath: selectedIcon,
        text: existingStat.text,
      }));
    } else {
      // If the icon path doesn't exist, clear the text
      setFormData((prevData) => ({
        ...prevData,
        iconPath: selectedIcon,
        text: "",
      }));
    }
  };

  const handleDelete = async (index) => {
    try {
      // Remove the selected item from the data array
      const updatedStatsData = [...countryStatsData];
      const deletedItem = updatedStatsData.splice(index, 1)[0];
      console.log(deletedItem);

      // Fetch the existing data from the backend
      const response = await fetch(
        `https://be-csc-map.mohamedradwan.me/api/country/${countryName.toUpperCase()}`
      );

      if (!response.ok) {
        console.error(
          `Failed to fetch data for country ${countryName}. Server responded with:`,
          response.status
        );
        // Handle error accordingly
        return;
      }

      const countryData = await response.json();
      console.log("Fetched country data:", countryData);

      const countryStats = countryData["Country Stats"]?.L || [];
      console.log("Country Stats", countryStats);

      // Remove the deleted item from the array
      countryStats.splice(index, 1);

      // Extract all other attributes from the existing data
      const existingAttributes = Object.entries(countryData)
        .filter(([key, value]) => key !== "CountryStats" && key !== "id")
        .reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {});

      // Remove 'S' keys from the payload
      const removeKeys = (obj) => {
        if (!obj || typeof obj !== "object") {
          return obj;
        }

        if (Array.isArray(obj)) {
          return obj.map(removeKeys);
        }

        if (obj.M) {
          // If 'M' attribute exists, use its value directly
          return removeKeys(obj.M);
        }

        return Object.fromEntries(
          Object.entries(obj).map(([key, value]) => {
            if (value && typeof value === "object" && "S" in value) {
              // Check if the value is not empty and is a string
              return [
                key,
                value.S !== undefined && value.S !== null && value.S !== ""
                  ? value.S
                  : "",
              ];
            } else if (Array.isArray(value)) {
              return [key, value.map(removeKeys)];
            } else {
              return [key, value];
            }
          })
        );
      };

      // Include all existing data along with the modified data (without 'S' keys)
      const updatedRow = removeKeys({
        ...existingAttributes,
        "Country Stats": countryStats,
      });

      // Update the backend with the modified data
      const putResponse = await fetch(
        `https://be-csc-map.mohamedradwan.me/api/update-country/${countryId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedRow),
        }
      );

      console.log("Request payload:", JSON.stringify(updatedRow));
      console.log("Response:", await putResponse.json());

      if (!putResponse.ok) {
        console.error(
          `Failed to update item with ID ${countryName}. Server responded with:`,
          putResponse.status
        );
        // Handle error accordingly
        return;
      }

      console.log("Deleted:", deletedItem);
      console.log("Updated Data:", countryStats);

      // Close the modal or perform any additional actions
      onClose();
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `https://be-csc-map.mohamedradwan.me/api/country/${countryName.toUpperCase()}`
      );

      if (!response.ok) {
        console.error(
          `Failed to fetch data for country ${countryName}. Server responded with:`,
          response.status
        );
        return;
      }

      const countryData = await response.json();
      console.log("Fetched country data:", countryData);

      const countryStats = countryData["Country Stats"]?.L || [];
      console.log("Country Stats", countryStats);

      // Check if the selected icon path already exists
      const existingStatIndex = countryStats.findIndex((stat) => {
        const iconPath = stat.iconPath?.S || (stat.M && stat.M.iconPath?.S);
        return iconPath === formData.iconPath;
      });
      console.log(formData.text);

      // If the icon path exists, update the text
      if (existingStatIndex !== -1) {
        console.log("exists");
        const existingStat = countryStats[existingStatIndex];
        console.log(existingStat.M.text.S);
        if (existingStat && existingStat.M.text) {
          existingStat.M.text.S = formData.text;
        }
      } else {
        // If the icon path doesn't exist, create a new array with the new data
        countryStats.push({
          iconPath: { S: formData.iconPath },
          text: { S: formData.text },
        });
      }

      // Extract all other attributes from the existing data
      const existingAttributes = Object.entries(countryData)
        .filter(([key, value]) => key !== "CountryStats" && key !== "id")
        .reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {});

      // Remove 'S' keys from the payload
      const removeKeys = (obj) => {
        if (!obj || typeof obj !== "object") {
          return obj;
        }

        if (Array.isArray(obj)) {
          return obj.map(removeKeys);
        }

        if (obj.M) {
          // If 'M' attribute exists, use its value directly
          return removeKeys(obj.M);
        }

        return Object.fromEntries(
          Object.entries(obj).map(([key, value]) => {
            if (value && typeof value === "object" && "S" in value) {
              // Check if the value is not empty and is a string
              return [
                key,
                value.S !== undefined && value.S !== null && value.S !== ""
                  ? value.S
                  : "",
              ];
            } else if (Array.isArray(value)) {
              return [key, value.map(removeKeys)];
            } else {
              return [key, value];
            }
          })
        );
      };

      // Include all existing data along with the new data (without 'S' keys)
      const updatedRow = removeKeys({
        ...existingAttributes,
        "Country Stats": countryStats,
      });
      console.log(updatedRow);
      const putResponse = await fetch(
        `https://be-csc-map.mohamedradwan.me/api/update-country/${countryId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedRow),
        }
      );

      console.log("Request payload:", JSON.stringify(updatedRow));
      console.log("Response:", await putResponse.json());

      if (!putResponse.ok) {
        console.error(
          `Failed to update item with ID ${countryName}. Server responded with:`,
          putResponse.status
        );
      }

      onClose();
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const iconOptions = [
    "/map icons/10.png",
    "/map icons/22.png",
    "/map icons/33.png",
    "/map icons/8.png",
    "/map icons/12.png",
    "/map icons/44.png",
    "/map icons/55.png",
    "/map icons/65.png",
    "/map icons/66.png",
  ];

  const iconNames = [
    "SANCTIONS/EXPORT",
    "KIDNAPPING",
    "TERRORISM",
    "PUBLIC HEALTH",
    "CRIME",
    "CONFLICT/WAR",
    "CORRUPTION",
    "IP PROTECTION",
    "CIVIL UNREST",
  ];

  return (
    <Modal onClose={onClose}>
      <div>
        <form onSubmit={handleSubmit} className={styles.formContainer}>
          <Dropdown onSelect={(selectedIcon) => handleIconChange(selectedIcon)}>
            <Dropdown.Toggle
              variant="primary"
              id="dropdown-basic"
              className={styles.menuIcons}
            >
              {selectedIcon ? (
                <img src={selectedIcon} alt="Selected Icon" />
              ) : (
                "Select Icon"
              )}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {iconOptions.map((icon, index) => (
                <Dropdown.Item
                  key={index}
                  eventKey={icon}
                  className={styles.menuIcons}
                >
                  <img src={icon} alt={`Icon ${index}`} />
                  {iconNames[index]}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          <FloatingLabel
            controlId="floatingTextarea2"
            label="Add Status to this Country"
          >
            <Form.Control
              as="textarea"
              placeholder="Leave a comment here"
              style={{ height: "100px" }}
              value={formData.text}
              name="text"
              onChange={handleInputChange}
            />
          </FloatingLabel>

          <Button type="submit" className={styles.submitButton}>
            Submit
          </Button>
        </form>
      </div>
      <ul className={styles.dataList}>
        {countryStatsData.map((data, index) => (
          <li key={index} className={styles.menuIcons}>
            <div>
              <img src={data.iconPath} alt="Icon" />
              <span>
                <strong>Status:</strong> {data.text}
              </span>
            </div>
            <Button
              onClick={() => handleDelete(index)}
              className={styles.deleteButton}
            >
              Delete
            </Button>
          </li>
        ))}
      </ul>
    </Modal>
  );
};

export default CountryStats;
