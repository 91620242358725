import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Form,
  Button,
  InputGroup,
  FormControl,
  FloatingLabel,
} from "react-bootstrap";
import styles from "./EditData.module.css";
import { Dropdown, DropdownButton } from "react-bootstrap";

const EditData = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [countryData, setCountryData] = useState({});
  const [countryStats, setCountryStats] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState("");
  const [inputText, setInputText] = useState("");

  useEffect(() => {
    if (location.state && location.state.countryData) {
      const data = location.state.countryData;
      setCountryData(data);

      // Check if countryStats exists and is in the expected format
      if (data["Country Stats"] && data["Country Stats"].L) {
        const stats = data["Country Stats"].L.map((stat) => ({
          iconPath: stat.M.iconPath.S,
          text: stat.M.text.S,
        }));
        setCountryStats(stats);
        console.log(data["Country Stats"].L);
      }
    } else {
      navigate("/");
    }
  }, [location, navigate]);

  const removeKeys = (obj) => {
    if (!obj || typeof obj !== "object") {
      return obj;
    }

    if (Array.isArray(obj)) {
      return obj.map(removeKeys);
    }

    if (obj.M) {
      // If 'M' attribute exists, use its value directly
      return removeKeys(obj.M);
    }

    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => {
        if (value && typeof value === "object" && "S" in value) {
          // Check if the value is not empty and is a string
          return [
            key,
            value.S !== undefined && value.S !== null && value.S !== ""
              ? value.S
              : "",
          ];
        } else if (Array.isArray(value)) {
          return [key, value.map(removeKeys)];
        } else {
          return [key, value];
        }
      })
    );
  };

  const handleSave = async () => {
    setIsLoading(true);

    const preparedCountryStats = countryStats.map((stat) => ({
      iconPath: stat.iconPath,
      text: stat.text,
    }));
    // Prepare the data for Country Stats as it was before
    const preparedData = {
      ...countryData,
      "Country Stats": preparedCountryStats,
    };

    // Now, use removeKeys function to clean up the prepared data
    const cleanedData = removeKeys(preparedData);

    console.log(cleanedData);

    try {
      const itemId = countryData.id.S; // Ensure this aligns with how you are storing the 'id' in your countryData
      const response = await fetch(
        `https://be-csc-map.mohamedradwan.me/api/update-country/${itemId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(cleanedData), 
        }
      );

      if (response.ok) {
        navigate("/countries-table");
      } else {
        console.error(
          "Failed to update the country data.",
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error updating country data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e, key) => {
    setCountryData((prevData) => ({
      ...prevData,
      [key]: { S: e.target.value },
    }));
  };

  const handleStatsChange = (index, field, value) => {
    setCountryStats((prevStats) =>
      prevStats.map((stat, idx) =>
        idx === index ? { ...stat, [field]: value } : stat
      )
    );
  };

  const handleIconSelect = (iconPath) => {
    setSelectedIcon(iconPath);
    const existingStat = countryStats.find(
      (stat) => stat.iconPath === iconPath
    );
    if (existingStat) {
      setInputText(existingStat.text);
    } else {
      setInputText("");
    }
  };

  const handleTextChange = (e) => {
    setInputText(e.target.value);
  };

  const handleAddOrUpdateStat = () => {
    // Check if the selected icon is already in the list
    const existingIndex = countryStats.findIndex(
      (stat) => stat.iconPath === selectedIcon
    );
    if (existingIndex !== -1) {
      // Update existing stat
      handleStatsChange(existingIndex, "text", inputText);
    } else {
      // Add new stat
      const newStat = { iconPath: selectedIcon, text: inputText };
      setCountryStats([...countryStats, newStat]);
    }
    // Clear the form
    setSelectedIcon("");
    setInputText("");
  };
  const handleDeleteStat = (indexToDelete) => {
    setCountryStats((currentStats) =>
      currentStats.filter((_, index) => index !== indexToDelete)
    );
  };

  const iconOptions = [
    "/map icons/10.png",
    "/map icons/22.png",
    "/map icons/33.png",
    "/map icons/8.png",
    "/map icons/12.png",
    "/map icons/44.png",
    "/map icons/55.png",
    "/map icons/65.png",
    "/map icons/66.png",
  ];

  const iconNames = [
    "SANCTIONS/EXPORT",
    "KIDNAPPING",
    "TERRORISM",
    "PUBLIC HEALTH",
    "CRIME",
    "CONFLICT/WAR",
    "CORRUPTION",
    "IP PROTECTION",
    "CIVIL UNREST",
  ];

  return (
    <div className={styles.mainContainer}>
      <h2>Edit Country Data</h2>
      <Form>
        <div className={styles.container}>
          {Object.entries(countryData).map(([key, value]) =>
            key !== "id" && key !== "Country Stats" ? (
              <Form.Group key={key} className="mb-3" controlId={`form${key}`}>
                <Form.Label>{key}</Form.Label>
                <Form.Control
                  type="text"
                  value={value.S || ""}
                  onChange={(e) => handleChange(e, key)}
                />
              </Form.Group>
            ) : null
          )}
          <div className={styles.statsContainer}>
            <h4>Country Stats</h4>
            <div className={styles.statsSection}>
              <InputGroup className={styles.statusController}>
                <DropdownButton
                  id="dropdown-icon-button-new-stat"
                  title={
                    selectedIcon ? (
                      <span>
                        <img
                          src={selectedIcon}
                          alt="Selected Icon"
                          style={{ width: "20px", marginRight: "10px" }}
                        />
                        {iconNames[iconOptions.indexOf(selectedIcon)] ||
                          "Select Icon"}
                      </span>
                    ) : (
                      "Select Icon"
                    )
                  }
                  onSelect={handleIconSelect}
                >
                  {iconOptions.map((icon, idx) => (
                    <Dropdown.Item
                      key={idx}
                      eventKey={icon}
                      className={styles.menuIcons}
                    >
                      <img
                        src={icon}
                        alt={`Icon ${idx}`}
                        style={{ width: "20px", marginRight: "10px" }}
                      />
                      {iconNames[idx]}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Add Status to this Country"
                  className={styles.textArea}
                >
                  <FormControl
                    placeholder="Text"
                    value={inputText}
                    onChange={handleTextChange}
                    style={{wordWrap: "break-word"}}
                  />
                </FloatingLabel>
                <Button onClick={handleAddOrUpdateStat}>Add</Button>
              </InputGroup>
              <div className={styles.listContainer}>
                <ul className={styles.dataList}>
                  {countryStats.map((data, index) => (
                    <li key={index} className={styles.menuIcons}>
                      <div className={styles.statsList}>
                        <img src={data.iconPath} alt="Icon" />
                        <span>
                          <strong>Status:</strong> {data.text}
                        </span>
                        <Button
                          onClick={() => handleDeleteStat(index)}
                          className={styles.deleteButton}
                        >
                          Delete
                        </Button>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <Button variant="primary" onClick={handleSave} disabled={isLoading}>
          {isLoading ? "Saving..." : "Save Changes"}
        </Button>
      </Form>
    </div>
  );
};

export default EditData;
